// this state is used to manage accessing brackets
/* eslint-disable */
import axios from 'axios';
axios.defaults.headers.common['Authorization'] = 'Bearer ZHD3JDUsW8yn6ZuDEGEb00lrbc21EOf2zVpxd7zelzQsNBVQxcdr4NuejLw7';
const state = {
    currentBracket: {},
    players: [],
    bracketStructure: [],
    bracketTypes:{
        simple_elimination: 'simple_elimination',
        double_elimination: 'double_elimination',
        round_robin: 'round_robin',
        battle_royale: 'battle_royale'
    },
    matchToReport: {},
    isPlayOff: false,
    bracketMatches: null,
    retrieveMatches: false,
    isThirdPlace: false,
};
const getters = {};
const actions = {
    setBracket({commit}, bracket){
        commit('setBracket', bracket);
    },
    getPlayers({commit}){
        // fetch players
        commit('setPlayers');
    },
    getBracketStructure({commit, state, rootState}, bracketId){
        // this needs to just get the structure stored in the bracket table ///
        console.log('in get structure: bracket id: '+ bracketId);
        commit('setBracketStructure', rootState.currentTournament.tournamentItem.bracket.structure);
    },
    updateMatch({commit, rootState, dispatch}, payload){
        const tournamentId = rootState.newTournament.tournament.id;
        const bracketId = rootState.newTournament.tournament.bracket.id;
        let payloadData = null;
        if(payload.isThirdPlace){
            payloadData = payload.isPlayOff
                ? {bracketId: bracketId,
                    match: payload.matchData,
                    isPlayOff: true,
                    isThirdPlace: true,
                }
                : {
                    bracketId: bracketId,
                    match: payload.matchData,
                    isThirdPlace: true,
                }
        }else {
            payloadData = payload.isPlayOff
                ? {
                    bracketId: bracketId,
                    match: payload.matchData,
                    isPlayOff: true,
                }
                : {
                    bracketId: bracketId,
                    match: payload.matchData
                }
        }
        return axios.post('user/tournament/'+tournamentId+'/update-bracket',payloadData)
            .then(response => {
                if(response.data.success){
                    dispatch('manageTournament/getMatches', null, {root:true});
                }
                console.log(response)
                return {
                    success: response.data.success,
                }
            }).catch(error => {
                console.log(error);
                return {
                    success: false,
                }
            })
    },
    playerUpdateMatch({commit, rootState, state, dispatch}, payload){
        const tournamentId = rootState.currentTournament.tournamentItem.id;
        const bracketId = rootState.currentTournament.tournamentItem.bracket.id;
       /* const payloadData = payload.isPlayOff
            ? {bracketId: bracketId, match: payload.matchData, isPlayOff: true}
            : {bracketId: bracketId, match: payload.matchData}*/
        console.log('before axios ============================== ')
        console.log(payload)
        console.log('before axios ============================== ')
        let matchData = new FormData();
        matchData.append('screen-shot-participant', payload.screenShot_participant);
        matchData.append('screen-shot-competitor', payload.screenShot_competitor);
        matchData.append('bracketId', bracketId);
        matchData.append('isPlayer', true);
        matchData.append('match', JSON.stringify(payload.matchData));
        if(payload.isThirdPlace){
            matchData.append('isThirdPlace', true);
        }
        if(payload.isPlayOff){
            matchData.append('isPlayOff', true);
        }

            return axios.post('user/tournament/'+tournamentId+'/update-bracket', matchData,  {
                headers:{"Content-Type": "multipart/form-data",}
            }).then(response => {
                if(response.data.success){
                    rootState.currentTournament.tournamentItem.bracket.structure = response.data.structure;
                    if(state.retrieveMatches){
                        console.log('retrieving matches ................')
                        console.log(response.data.matches);
                        dispatch('getMatches');
                    }
                    commit('setBracketStructure', response.data.structure);
                }
                return {
                    success: response.data.success,
                }
            }).catch(error => {
                console.log(error);
                return {
                    success: false,
                }
            })
    },
    deleteScreenShot({commit}, payload){
        return axios.delete('user/match/'+payload.match_id+'/participant/'+payload.participantId+'/delete/screen-shot')
            .then(response => {
                if(response.data.success){
                    commit('deleteScreenShot', payload.participantWho);
                    return {
                        success: true,
                    }
                }else{
                    return {
                        success: false,
                    }
                }
            }).catch(error => {
                return {
                    success: false,
                }
            });
    },
    setMatchToReport({commit}, matchData){
        commit("setMatchToReport", matchData);
    },
    getMatches({commit, rootState}){
        const tournamentId = rootState.currentTournament.tournamentItem.id;
        const bracketId = rootState.currentTournament.tournamentItem.bracket.id;
        return axios.get('tournament/'+tournamentId+'/bracket/'+bracketId+'/group-matches')
            .then(response => {
                console.log('%cgetting matches.... ', 'color: red');
                console.log(response)
                console.log('%cgetting matches.... ', 'color: red');
                if(response.data.success){
                    commit('setGroupMatches', response.data.matches)
                    return {
                        success: true,
                    }
                }else{
                    return {
                        success: false,
                    }
                }
            }).catch(error => {
                console.log(error);
            })
    },
    setRetrieveMatches({commit}, shouldRetrieve) {
        commit('setRetrieveMatches', shouldRetrieve);
    }
};
const mutations = {
    setBracket(state, bracket){
        state.currentBracket = bracket;
    },
    setPlayers(state, players){
        state.players = players;
    },
    setBracketStructure(state, bracketStructure){
        state.bracketStructure = null;
        state.bracketStructure = bracketStructure;
    },
    setMatchToReport(state, matchData){
        console.log('set match to report _____________________')
        console.log(matchData);
        console.log('set match to report _____________________')
        state.matchToReport = matchData.match;
        state.isPlayOff = matchData.isPlayOff;
        state.isThirdPlace = matchData.isThirdPlace
    },
    setGroupMatches(state, matches){
        console.log('matches from mutation')
        console.log(matches)
        state.bracketMatches = matches.filter(match => {return match.round_type === 'GS'});
    },
    setRetrieveMatches(state, shouldRetrieve){
        state.retrieveMatches = shouldRetrieve;
    },
    deleteScreenShot(state, participantWho){
        if(participantWho === 1){
            state.matchToReport.match.participant.screenShot = null;
        }
        if(participantWho === 2){
            state.matchToReport.match.competitor.screenShot = null;
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,

}
