<template>
    <div>
        <script type="application/javascript" defer src="https://player.twitch.tv/js/embed/v1.js"
            ref="twitchScript"></script>
        <!-- Lgame NavBar -->
        <LgameNavBar></LgameNavBar>
        <div class="main-page-wrapper">

            <div class="main-page-landing-area d-flex flex-column justify-content-center align-items-center position-relative px-1 px-md-0"
                :style="{
                    backgroundImage: 'url('+require('@/assets/hero-bg.png')+')',
                }">
                <div
                    class="landing-content  w-100 d-flex flex-column-reverse flex-md-row justify-content-center justify-content-md-between align-items-center w-100 container mt-md-4 mt-lg-0 m-lg-0 px-0">
                    <div class="card-participate d-flex flex-column align-items-center align-items-md-start mt-md-4">
                        <div>
                            <h2 class="header-one text-md-left">Ramadan gaming cup</h2>
                            <h3 class="header-two text-md-left mb-3">By MT CASH</h3>
                            <h6 class="header-subtitle text-md-left">Tournoi sur FREE FIRE</h6>
                        </div>
                        <ParticipateCard class="mt-3 w-75" @init-pop-up="initPopUp" @view-bracket="viewBracket">
                        </ParticipateCard>
                    </div>
                    <div class="logo-event-container ">
                        <img :src="require('@/assets/tournament-logo.png')" width="380px" />
                    </div>
                </div>
                <div class="logos-container d-flex justify-content-end align-items-center position-absolute w-100">
                    <div class="p-2 mr-1 mr-lg-5 d-flex align-items-end">
                        <span class="sponsored-by py-2 mb-2 mr-3">SPONSORED BY</span>
                        <img class="mt-cash-logo" height="64px" :src="require('@/assets/logo-mtcash.png')" />
                    </div>
                </div>
            </div>

            <div class="how-to-participate-container px-2 px-md-0 py-3 py-lg-5 m-lg-0 w-100" :style="{
                backgroundImage: 'url(' + require('@/assets/howto-bg.png') + ')',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 100%'
            }">
                <div
                    class="container p-0 d-flex flex-column flex-md-row justify-content-md-between align-items-md-center">
                    <div class="position-relative d-flex flex-column flex-md-row justify-content-between w-100 p-0 m-0">
                        <h2 class="section-title text-left">Comment participer</h2>
                        <h2 class="arabic-title text-left text-md-right my-0" dir="rtl">كيفية المشاركة</h2>
                    </div>
                </div>
                <div class="container p-0 w-100">
                    <div class="how-to-content row  my-4 py-4 m-0" :style="{
                        backgroundImage: 'url(' + require('@/assets/phone-blue-background.png') + ')'
                    }">
                        <div class="description-steps pl-2 col col-md-6">
                            <div class="step-item-container mb-5">
                                <div class="step-item-header d-flex justify-content-start align-items-center mb-2">
                                    <div>
                                        <img :src="require('@/assets/step1.png')" width="32px" />
                                    </div>
                                    <div class="ml-2">
                                        <h2 class="step-title text-left m-0">Télécharger l’application MT Cash</h2>
                                        <h2 class="step-title-arabic text-left mt-1 m-0" dir="rtl">حمِّل تطبيق
                                            &nbsp;&nbsp;&nbsp;MT CASH</h2>
                                    </div>
                                </div>
                                <div class="step-item-content px-5">
                                    <p class="step-descr text-left px-0 mb-2">
                                        Tous les membres de l'équipe sont tenus de télécharger l’application MT Cash
                                        à partir des stores officiels Google Play ou App Store.
                                    </p>
                                    <p class="step-descr text-right px-0" dir="rtl">
                                        كل أفراد الفريق ملزمين بتحميل تطبيق MT Cash من المتاجر الرسمية Google Play أو
                                        App
                                        Store.
                                    </p>
                                </div>
                                <!-- only on mobile -->
                                <div class="only-mobile">
                                    <div class="d-flex justify-content-center align-items-center">
                                        <div>
                                            <img :src="require('@/assets/phone.png')" width="70%" height="80%" />
                                        </div>
                                        <div>
                                            <div>
                                                <a class="store-item"
                                                    href="https://play.google.com/store/apps/details?id=ma.iam.mtcash&fbclid=IwAR18XdQzbBx7tK_iixnYDPIwEYrrPbo_WHIbKmfRJQWtA6oQLFSttc-aSBM">
                                                    <img :src="require('@/assets/play-store.png')" width="120px" />
                                                </a>
                                            </div>
                                            <div>
                                                <a class="store-item"
                                                    href="https://apps.apple.com/fr/app/mt-cash/id1486344533?fbclid=IwAR20cH85Au863zlG_lrW4Hd3S8um5flGdLRUQhAqj5KD7HYNh4hnafr-Z88">
                                                    <img :src="require('@/assets/app-store.png')" width="120px" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-around align-items-center mt-4">
                                        <div class="mr-2 gift-img-container gift-container p-3">
                                            <img :src="require('@/assets/cadeau.png')" width="60px" />
                                        </div>

                                        <div class="p-2 gift-desc-container gift-description-container">
                                            <p class="text-left text-white m-0 cadeau-desc">
                                                Chaque joueur dans le tournoi bénéficiera d’un solde de 20Dhs offert sur
                                                le
                                                compte MT Cash.
                                            </p>
                                            <p class="text-right text-white m-0 cadeau-desc" dir="rtl">
                                                كل لاعب في البطولة غادي يمكن ليه يستافد من 20 درهم هدية
                                                على حسابه MT Cash.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <!-- only end -->

                                <div class="store-links d-flex justify-content-center align-items-center">
                                    <div>
                                        <a class="store-item"
                                            href="https://play.google.com/store/apps/details?id=ma.iam.mtcash&fbclid=IwAR18XdQzbBx7tK_iixnYDPIwEYrrPbo_WHIbKmfRJQWtA6oQLFSttc-aSBM">
                                            <img :src="require('@/assets/play-store.png')" />
                                        </a>

                                    </div>
                                    <div>
                                        <a class="store-item"
                                            href="https://apps.apple.com/fr/app/mt-cash/id1486344533?fbclid=IwAR20cH85Au863zlG_lrW4Hd3S8um5flGdLRUQhAqj5KD7HYNh4hnafr-Z88">
                                            <img :src="require('@/assets/app-store.png')" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="step-item-container mb-5">
                                <div class="step-item-header d-flex justify-content-start align-items-center mb-2">
                                    <div>
                                        <img :src="require('@/assets/step2.png')" width="32px" />
                                    </div>
                                    <div class="ml-2">
                                        <h2 class="step-title text-left m-0">Créer un compte MT Cash</h2>
                                        <h2 class="step-title-arabic text-left m-0" dir="rtl">افتح حساب
                                            &nbsp;&nbsp;&nbsp;MT
                                            CASH</h2>
                                    </div>
                                </div>
                                <div class="step-item-content px-5">
                                    <p class="step-descr text-left px-2 mb-2">
                                        Après avoir téléchargé l’application, les membres de l'équipe doivent créer un
                                        compte MT Cash en suivant les étapes ci-après:
                                    </p>
                                    <p class="step-descr text-right px-2" dir="rtl">
                                        بعد تحميل التطبيق، يجب على أفراد الفريق فتح حساب مجاني MT Cash بإتباع المراحل
                                        التالية :
                                    </p>
                                    <div>
                                        <iframe class="rounded" width="100%" height="280px"
                                            src="https://www.youtube.com/embed/KcINqezeOew"
                                            title="Souscription au service MT Cash" frameborder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            allowfullscreen></iframe>
                                    </div>
                                </div>

                            </div>

                            <div class="step-item-container">
                                <div class="step-item-header d-flex justify-content-start align-items-center mb-2">
                                    <div>
                                        <img :src="require('@/assets/step3.png')" width="32px" />
                                    </div>
                                    <div class="ml-2">
                                        <h2 class="step-title text-left m-0">S’inscrire au tournoi</h2>
                                        <h2 class="step-title-arabic text-left m-0" dir="rtl">التسجيل في البطولة</h2>
                                    </div>
                                </div>
                                <div class="step-item-content px-5">
                                    <p class="step-descr text-left px-0 mb-2">
                                        Pour s'inscrire au tournoi, le capitaine de chaque équipe doit renseigner ses
                                        informations et celles de son équipe et envoyer les justificatifs de création
                                        des comptes de tous les membres de l’équipe sur <a class="link-to"
                                            href="https://discord.gg/Dz7j6A5JTc">Discord</a> (un
                                        screenshot du compte MT Cash: Page «<a class="link-to"
                                            href="imgs/mon-espace.jpeg">Mon Espace</a>»).
                                    </p>
                                    <p class="step-descr text-right px-0" dir="rtl">
                                        يجب على قائد كل فريق التسجيل في البطولة من خلال إكمال معلومات فريقه و إرسال ما
                                        يثبت
                                        إنشاء حسابات أعضاء فريقه على <a class="link-to"
                                            href="https://discord.gg/Dz7j6A5JTc">Discord</a> (لقطة
                                        شاشة
                                        لحساب MT Cash : صفحة "<a class="link-to" href="imgs/mon-espace.jpeg">Mon
                                            Espace</a>"
                                        ).
                                    </p>
                                </div>
                                <div class="game-card py-2">
                                    <img :src="require('@/assets/freefire-card.png')" width="100%" />
                                </div>
                                <div class="mb-5">
                                    <ParticipateCard class="text-center w-100" :outlined="true" @init-pop-up="initPopUp"
                                        @view-bracket="viewBracket"></ParticipateCard>
                                </div>
                            </div>
                        </div>
                        <div class="phone-container col col-md-6 mt-5 px-3">
                            <div>
                                <img :src="require('@/assets/phone.png')" width="100%" height="780px" />
                            </div>
                            <div class="d-flex justify-content-around align-items-center mt-5">
                                <div class="gift-img-container p-3 mr-2">
                                    <img :src="require('@/assets/cadeau.png')" width="80px" />
                                </div>

                                <div class="gift-desc-container p-2">
                                    <p class="text-center text-left m-0 cadeau-desc text-white">
                                        Chaque joueur dans le tournoi bénéficiera d’un
                                        solde de 20 dhs offert sur le compte MT Cash.
                                    </p>
                                    <p class="text-center text-left m-0 cadeau-desc text-white" dir="rtl">
                                        كل لاعب في البطولة غادي يمكن ليه يستافد من 20 درهم مهداة من طرف
                                        MT Cash.
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>

            <div class="prizes-container my-3 my-lg-5 p-lg-0 py-5 w-100 position-relative">
                <div class="penguin-container image-container position-absolute">
                    <img :src="require('@/assets/penguin.png')" width="680" />
                </div>
                <div class="container d-flex flex-column flex-md-row justify-content-md-between align-items-md-center">
                    <div class="position-relative d-flex flex-column flex-md-row justify-content-between w-100 p-0 m-0">
                        <h2 class="section-title text-left">Prix</h2>
                        <h2 class="arabic-title text-left text-md-right my-0" dir="rtl">الجوائز</h2>
                    </div>
                </div>
                <div class="container">
                    <div class="row px-3 px-md-0" style="filter: drop-shadow(1px 1px 2px rgba(0,0,0,0.2))">
                        <div class="col-sm prize-card mb-2 mb-md-0 mr-md-1 p-2 position-relative"
                            style="background-color:#F7F7F7; border: 2px solid #E5AD04">
                            <!--<img :src="require('@/assets/gold-prize.png')"  width="100%" /> -->
                            <div class="position-absolute vertical-line" style="background-color: #E5AD04" width="10px"
                                height="100%"></div>
                            <div class="d-flex align-items-center p-2 px-4">
                                <div class="mx-4">
                                    <img :src="require('@/assets/gold-trophy.png')" width="64px" />
                                </div>
                                <div>
                                    <h3 class="prize-title gold-prize text-left">Première place
                                    </h3>
                                    <h3 class="prize-title gold-prize text-left">
                                        - <span class="prize-title-arabic gold-prize">المرتبة الأولى</span>
                                    </h3>
                                </div>
                            </div>
                            <div class="prize-desc-container p-2 px-4">
                                <p class="prize-desc text-left">
                                    Le montant total du prix s'élève à 10 000 Dhs pour l'équipe gagnante.
                                    (Chacun des joueurs de l'équipe persevra un montant de 2 500 Dhs
                                    sur son compte MT Cash).
                                </p>
                                <p class="prize-desc text-right" dir="rtl">
                                    تتضمن الجائزة النقدية الإجمالية <span dir="ltr">10 000</span> درهم للفريق الفائز
                                    (<span dir="ltr">2 500</span> درهم لكل لاعب في الفريق و التي سيتم تحويلها إلى
                                    حسابه MT Cash)
                                </p>
                            </div>

                        </div>
                        <div class="col-sm prize-card ml-md-1 p-2 position-relative"
                            style="background-color: #F7F7F7; border: 2px solid #9E9E9E">
                            <!--<img :src="require('@/assets/silver-prize.png')"  width="100%" /> -->
                            <div class="position-absolute vertical-line" style="background-color: #9E9E9E"></div>
                            <div class="d-flex align-items-center p-2 px-4">
                                <div class="mx-4">
                                    <img :src="require('@/assets/silver-trophy.png')" width="64px" />
                                </div>
                                <div>
                                    <div>
                                        <h3 class="prize-title silver-prize text-left">Deuxième place
                                        </h3>
                                        <h3 class="prize-title silver-prize text-left">
                                            - <span class="prize-title-arabic silver-prize">المرتبة الثانية</span>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <div class="prize-desc-container p-2 px-4">
                                <p class="prize-desc text-left">
                                    Le montant total du prix s'élève à 5 000 Dhs pour l'équipe gagnante.
                                    (Chacun des joueurs de l'équipe persevra un montant de 1 250 Dhs
                                    sur son compte MT Cash).
                                </p>
                                <p class="prize-desc text-right" dir="rtl">
                                    تتضمن الجائزة النقدية الإجمالية <span dir="ltr">5 000</span> درهم للفريق المحتل
                                    للمركز الثاني (<span dir="ltr">1 250</span> درهم لكل لاعب في الفريق و التي سيتم
                                    تحويلها إلى حسابه MT Cash)
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <!--<div class="container py-4 mt-2">
                    <div class="first-place d-flex justify-content-start align-items-start">
                        <div class="prize-desc-container ml-2">
                            <div>
                                <h3 class="prize-title text-left">Première place
                                </h3>
                                <h3 class="prize-title text-left">
                                    <span class="high-light"> - </span><span class="prize-title-arabic">المرتبة الأولى</span>
                                </h3>
                            </div>
                            
                            <p class="prize-desc text-justify">
                                Le montant total du prix en espèces s'élève à 10 000 DH pour l'équipe gagnante, soit 2 500 DH par joueur, qui seront versés sur leur compte MT Cash.
                            </p>
                            <p class="prize-desc text-justify" dir="rtl">
                                تتضمن الجائزة النقدية الإجمالية 10000 درهم للفريق الفائز (2500 درهم لكل لاعب في الفريق و التي سيتم تحويلها إلى حسابه MT Cash)
                            </p>
                        </div>
                    </div>
                    <div class="second-place d-flex justify-content-start align-items-start my-2">
                        <div class="mr-2">
                            <img :src="require('@/assets/silver-trophy.png')"  width="64px" />
                        </div>
                        <div class="prize-desc-container ml-2">
                            <div>
                                <h3 class="prize-title text-left">Deuxième place
                                </h3>
                                <h3 class="prize-title text-left">
                                    <span class="high-light"> - </span><span class="prize-title-arabic">المرتبة الثانية</span>
                                </h3>
                            </div>
                            
                            <p class="prize-desc text-justify">
                                Le montant total du prix en espèces s'élève à 5 000 DH pour l'équipe classée deuxième, soit 1 250DH par joueur, qui seront versés sur leur compte MT Cash.
                            </p>
                            <p class="prize-desc text-justify" dir="rtl">
                                تتضمن الجائزة النقدية الإجمالية 5000 درهم للفريق المحتل للمركز الثاني (1250 درهم لكل لاعب في الفريق و التي سيتم تحويلها إلى حسابه MT Cash)
                            </p>
                        </div>
                    </div>-->
                <!-- <div class="third-place d-flex justify-content-start align-items-start">
                        <div class="mr-2">
                            <img :src="require('@/assets/roulette.png')"  width="64px" />
                        </div>
                        <div class="prize-desc-container ml-2">
                            <div>
                                <h3 class="prize-title text-left">TOMBOLA POUR LES équipes participantes
                                </h3>
                                <h3 class="prize-title text-left">
                                    <span class="high-light"> - </span><span class="prize-title-arabic">قرعة للفرق المشاركة</span>
                                </h3>
                            </div>
                            <p class="prize-desc text-left">
                                Toutes les équipes participantes dans le tournoi auront la chance de participer dans une tombola de 2 000Dhs (chaque joueur 
                                de l’équipe gagnante recevra 500 dirhams sur son compte MT Cash)
                            </p>
                            <p class="prize-desc text-right" dir="rtl">
                                ستتاح لجميع الفرق المشاركة في البطولة فرصة المشاركة في سحب <span dir="ltr">2 000</span> درهم (سيتوصل كل لاعب من الفريق الفائز بمبلغ 500 درهم في حسابه MT Cash)
                            </p>
                        </div>
                    </div> 
                </div>-->
            </div>

            <div class="bracket-container container my-5 my-lg-5 p-lg-0 py-4 w-100" id="bracket">
                <div class="d-flex flex-column flex-md-row justify-content-md-between align-items-md-center mb-2">
                    <div class="position-relative d-flex flex-column flex-md-row justify-content-between w-100 p-0 m-0">
                        <h2 class="section-title text-left">Bracket</h2>
                        <h2 class="arabic-title text-left text-md-right my-0" dir="rtl">النتائج</h2>
                    </div>

                </div>
                <div class="bracket d-flex justify-content-center align-items-center">
                    <BracketViewer></BracketViewer>
                </div>
            </div>

            <div class="stream-container container my-3 my-lg-5 p-lg-0 py-4 w-100" style="background-color:#F7F7F7;"
                id="stream">
                <div class="d-flex flex-column flex-md-row justify-content-md-between align-items-md-center mt-4 mb-2">
                    <div class="position-relative d-flex flex-column flex-md-row justify-content-between w-100 p-0 m-0">
                        <h2 class="section-title text-left">Stream</h2>
                        <h2 class="arabic-title text-left text-md-right my-0" dir="rtl">البث المباشر</h2>
                    </div>

                </div>
                <div class="stream" ref="twitchPlayer"></div>
            </div>
        </div>
        <FaqList></FaqList>
        <div class="position-relative mt-footer" style="background-color:#F7F7F7;">
            <img :src="require('@/assets/phone-blue-background.png')" width="142" alt="">
        </div>

        <!-- lgame footer -->
        <LgameFooter></LgameFooter>

        <!-- Lgame participate pop up-->
        <LgameParticipatePopUp :pop-up-type="popUpOptions.type" :title="popUpOptions.title"
            :pop-up-message-body="popUpOptions.message" :with-options="popUpOptions.withOptions"
            :options="popUpOptions.options" v-if="showPopUp" @modal-closed="abortAction" @acceptAction="acceptAction"
            @abortAction="abortAction"></LgameParticipatePopUp>

    </div>
</template>

<script>
    import BracketViewer from './BracketViewer.vue';
    import ParticipateCard from './ParticipateCard.vue';
    import LgameFooter from './LgameFooter.vue';
    import LgameNavBar from './NavBar.vue';
    import LgameParticipatePopUp from './LgameParticipatePopUp.vue';
    import FaqList from './FAQ/FaqList.vue';

    export default{
        name: "MainPage",
        components:{
            ParticipateCard,
            BracketViewer,
            LgameParticipatePopUp,
            LgameNavBar,
            LgameFooter,
            FaqList
        },
        data(){
            return{
                twitchPlayer: null,
                playerLoaded: false,

                showPopUp: false,
                popUpOptions: {
                    type: '',
                    title: '',
                    message: '',
                },
            }
        },
        computed:{
            isLoaded: {
                get() {
                    return !!this.$store.state.currentTournament.tournamentItem;
                },
                set(val) {
                    return val;
                }
            },
            currentTournament: {
                get() {
                    return this.$store.state.currentTournament.tournamentItem ? this.$store.state.currentTournament.tournamentItem : null;
                }
            },
        },
        methods: {
            initPlayer(){
                // eslint-disable-next-line
                this.twitchPlayer = new Twitch.Player(this.$refs["twitchPlayer"], {
                    width: '100%',
                    height: '100%',
                    channel: 'geekmaroctv',
                });
                this.twitchPlayer.setVolume(0.5);
                this.playerLoaded = true
                // eslint-disable-next-line
                this.twitchPlayer.addEventListener(Twitch.Player.ONLINE, () => {
                    /*this.$store.dispatch('currentTournament/setTwitchLive', {
                        isLive: true,
                    })*/
                });
                // eslint-disable-next-line
                this.twitchPlayer.addEventListener(Twitch.Player.ENDED, () => {
                    
                    /*this.$store.dispatch('currentTournament/setTwitchLive', {
                        isLive: false,
                    })*/
                });   
            },
            acceptAction(payload) {
                this.showPopUp = false;
                
                if (payload && payload.hasCustomFields) {
                    if (payload.teamId) {
                        this.$store.dispatch('currentTournament/setParticipantTeam', {
                            teamId: payload.teamId,
                            customFieldsValue: payload.customFieldsValues,
                        }).then(() => {
                            this.$store.dispatch('currentTournament/triggerLoading', false);
                        });
                    }
                    else {
                        this.$store.dispatch(this.popUpOptions.popUpDispatch, {
                            customFieldsValue: payload.customFieldsValues,
                        }).then(() => {
                            this.$store.dispatch('currentTournament/triggerLoading', false);
                        });
                    }
                } else {
                    if (payload && payload.teamId) {
                        this.$store.dispatch('currentTournament/setParticipantTeam', {
                            teamId: payload.teamId
                        }).then(() => {
                            this.$store.dispatch('currentTournament/triggerLoading', false);
                        });
                    }
                    else {
                        console.log('payload from pop up action ================= ')
                        console.log(payload)
                        console.log('payload from pop up action ================= ')
                        if (this.popUpOptions.dispatchData) {
                            this.$store.dispatch(this.popUpOptions.popUpDispatch, this.popUpOptions.dispatchData)
                                .then(() => {
                                    this.$store.dispatch('currentTournament/triggerLoading', false);
                                });
                        } else {
                            this.$store.dispatch(this.popUpOptions.popUpDispatch).then(() => {
                                this.$store.dispatch('currentTournament/triggerLoading', false);
                            });
                        }
                    }
                }
            },
            abortAction() {
                this.showPopUp = false;
                this.$store.dispatch('currentTournament/triggerLoading', false);
            },
            initPopUp(options) {
                this.popUpOptions = options;
                this.showPopUp = true;
            },
            viewBracket(){
                this.$refs.bracketTrigger.click();
            },
        },
        created(){
            this.$store.dispatch('currentUser/authCheck').then(response => {
                if (response.success) {
                    this.$store.dispatch('currentUser/setCurrentUser');
                }
            });
            console.log("getting tournament ...")
            this.$store.dispatch('currentTournament/getTournament', 151);
        },
        mounted() {
            const resizeOps = () => {
                document.documentElement.style.setProperty("--vh", window.innerHeight * 0.01 + "px");
            };

            resizeOps();
            window.addEventListener("resize", resizeOps);

            this.$refs['twitchScript'].onload = () => {
                this.initPlayer();
            }
        }
    }

</script>

<style scoped>
.main-page-wrapper{
    background-color:#F7F7F7;
}
.header-one{
    font-family: 'monument_extendedultrabold', sans-serif;
    text-transform: uppercase;
    font-size: 32pt;
}
.header-two{
    font-family: 'monument_extendedultrabold', sans-serif;
    font-size: 26pt;
}
.header-subtitle{
    font-family: 'Montserrat', sans-serif;
    font-weight:300;
    font-size: 11pt;
}
.mt-footer{
    background-color:#F7F7F7;
}
.sponsored-by{
    font-family: 'monument_extendedregular', sans-serif;
}
.prizes-header-ar{
    z-index:2;
}
.penguin-container{
    right: 0;
    top: -70%;
    z-index: 0;
}
.main-page-landing-area{
    min-height: calc(var(--vh, 1vh) * 100);
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.logo-event-container{
    filter: drop-shadow(0 0 4px rgba(0, 0,0,0.4));
}
.logos-container{
    bottom: 0;
}
.how-to-content{
    background-repeat: no-repeat;
    background-position: 25% 25%;

}
.section-title{
    color:var(--main-color) !important;
    font-family: 'monument_extendedultrabold', sans-serif;
    font-size: 28pt;
}
.section-title-stroke{
    font-family: 'monument_extendedregular', sans-serif;
    font-size: 32pt;
    color: transparent;
    position: absolute;
    top: 6px;
    left: 6px;
    -webkit-text-stroke: 1px var(--secondary-color);
}
.arabic-title{
    font-family: 'rtl_saudibold', sans-serif !important;
    color:#FA7200 !important;
    z-index:5;
}

.gift-img-container{
    background-color: orangered;
    transform: scale(1.08);
}
.gift-desc-container{
    background-color: orangered;
}

.stream{
    height: 580px;
}
.bracket{
    height: 80vh;
    background-color: var(--main-color);
    overflow: hidden;
}

.prize-title{
    color: var(--main-color);
    text-transform: uppercase;
    font-family: 'monument_extendedultrabold', sans-serif;
    font-size: 16pt;
    line-height: 100%;
}
.prize-card{
    filter: drop-shadow(2px 2px 4px rgba(0, 0,0,0.1));
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 16px), calc(100% - 16px) 100%, 0 100%);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-color: white;
}
.vertical-line{
    height: 100%;
    width:6px;
    top:0;
    left:0;
}
.gold-prize{
    color: #E5AD04 !important;
}
.silver-prize{
    color: #9E9E9E !important;
}
.high-light{
    color:var(--secondary-color);
}
.prize-title-arabic{
    color: var(--secondary-color);
    font-family: 'rtl_saudibold', sans-serif ;
    font-size: 16pt;

}
.prize-desc{
    color: #5A5A5A;
    font-family: 'Montserrat', sans-serif;
    font-weight:900;
    font-size: 12pt;
}
.cadeau-desc{
    color: #7B7B7B;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
}
.step-title{
    font-family:'monument_extendedregular', sans-serif;
    font-size: 12pt;
    color: var(--main-color);
    font-size: 16pt;
}
.step-title-arabic{
    font-family: 'rtl_saudibold', sans-serif ;
    color: var(--secondary-color);
    font-size: 14pt;
}
.step-descr{
    font-family:'Montserrat', sans-serif;
    color: #7B7B7B;
    font-weight: 500;
}
.store-item:hover img{
    transform: scale(1.01);
}

.only-mobile{
    display: none;
}
.link-to{
    color: var(--main-color);
    text-decoration: underline;
}
.krafton-copyright-footer-container{
    min-height:142px;
}
.krafton-copyright-footer{
    height: 160px;
    background-color: #4C4C4C !important;
    bottom: -54px;
}
@media screen and (max-width: 800px){
    
}

@media screen and (max-width: 768px) {
    .header-one{
    font-family: 'monument_extendedultrabold', sans-serif;
    text-transform: uppercase;
    font-size: 24pt;
    }
    .header-two{
        font-family: 'monument_extendedultrabold', sans-serif;
        font-size: 18pt;
    }
    .header-subtitle{
        font-family: 'Montserrat', sans-serif;
        font-weight:300;
        font-size: 10pt;
    }
    .how-to-participate-container{
        background-image: url('@/assets/mobile-bg.png')!important;
    }
    .penguin-container{
        top: -10%;
    }
    .prize-card{
        background-position: bottom right;
        background-size: contain;
    }
    .cadeau-desc{
        font-size:10pt;
    }
    .penguin-container img{
        width: 446px !important;
    }
    .krafton-copyright-footer img{
    
        height: 60px !important;
    }
    .main-page-landing-area{
    background-image:url('@/assets/hero-bg.png') !important;
}
    .logo-event-container img{
        width: 240px;
    }
    .section-title, .arabic-title{
        font-size: 18pt;
    }
    .phone-container{
        display: none;
    }
    .store-links{
        display: none !important;
    }
    .section-title-stroke{
        font-size: 18pt;
        top: 3px;
        left: 3px;
    }
    .how-to-content{
        background-position: center 12%;
        background-repeat: no-repeat;
        background-size: 80%;
    }
    .stream{
        width: 100% !important;
        height: 360px;
    }
    .sponsored-by{
       display: none;
    }
    .kraftoon-logo{
        height:42px;
    }
    .mt-cash-logo{
        height: 42px;
    }

    .only-mobile{
        display: block;
    }
    .prize-title{
        font-family: 'monument_extendedregular',sans-serif;
        font-size: 14pt;
    }
    
}
</style>