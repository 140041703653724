<template>
    <footer class="lgame-footer footer w-100">
        <div class="geekmaroc-link-container position-relative px-2">
                    <h5 class="geekmaroc-link font-weight-bold text-nowrap text-center text-md-left pt-4 pt-md-0 py-md-1 m-0" >
                        Powered by:<a class="geekmaroc-link font-weight-bold text-nowrap" href="https://geekmaroc.agency"> GEEK Maroc Agency</a>
                    </h5>
            </div>
        <div class="lgame-footer-container container-fluid pt-5 pb-4" id="lgame-footer">
            <div class="container">
                <div class="my-2 w-100">
                    <div
                        class="lgame-footer-content d-flex flex-sm-column flex-md-row justify-content-around align-items-start py-4 w-100">
                        <div class="lgame-footer-block w-25 text-left">
                            <div class="w-100">
                                <img alt="" class="lgame-logo my-2" width="140px" height="60px"
                                    :src="require('@/assets/Lgame_logo.svg')" />
                            </div>
                            <p class="footer-paragraph text-left text-justify">
                                Grâce à Lgame.gg, organiser, gérer, et automatiser vos tournois esport dans un seul
                                endroit !
                            </p>
                            <div class="d-flex justify-content-between align-items-center mt-0">
                                <div class="mt-0">
                                    <a class="mr-2" href="https://www.instagram.com/lgame.gg/" target="_blank">
                                        <img alt="" class="icon-social" :src="require('@/assets/rs_images/Insta.svg')">
                                    </a>
                                </div>
                                <div class=" mt-0">
                                    <a class="mr-2" href="https://www.facebook.com/lgame.gg" target="_blank">
                                        <img alt="" class="icon-social" :src="require('@/assets/rs_images/facebook.svg')">
                                    </a>
                                </div>
                                <div class=" mt-0">
                                    <a class="mr-2" href="https://www.twitch.tv/lgame_gg" target="_blank">
                                        <img alt="" class="icon-social" :src="require('@/assets/rs_images/twitch.svg')">
                                    </a>
                                </div>
                                <div class="mt-0">
                                    <a class="mr-2" href="https://www.linkedin.com/company/geek-maroc/" target="_blank">
                                        <img alt="" class="icon-social" :src="require('@/assets/rs_images/linkedin-icon.svg')">
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="lgame-footer-block d-flex justify-content-center align-items-center">
                            <div class="m-0 text-left">
                                <div class="limiter my-2"></div>
                                <h2 class="footer-title text-uppercase text-left">Ressources</h2>
                                <!--<router-link to="/services" class="footer-link">Services</router-link>
                                    <br>-->
                                <a href="https://lgame.gg/contact" target="_blank" class="footer-link text-left">Business inquiries</a>
                                <br>
                                <a href="https://lgame.gg/about" target="_blank" class="footer-link text-left">About</a>
                                <br>
                            </div>
                        </div>
                        <div class="lgame-footer-block d-flex justify-content-center align-items-center">
                            <div class="m-0 text-left">
                                <div class="limiter my-2"></div>
                                <h2 class="footer-title text-uppercase text-left">Légal</h2>
                                <a href="https://lgame.gg/legal" target="_blank" class="footer-link text-left">Mentions légales</a><br>
                                <a href="https://lgame.gg/general-conditions" target="_blank" class="footer-link text-left">Conditions d'utilisation
                                </a><br>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid text-center" id="lgame-footer-copyright">
            MADE WITH &#x2764; BY GEEK Maroc &copy; {{ currentYear }}
        </div>
    </footer>
</template>
<script>
import moment from "moment";
export default {
    name: 'LgameFooter',
    computed: {
        currentYear: {
            get() {
                return moment().year();
            }
        }
    }
}
</script>
<style scoped>
footer{
   /* background-color: #4C4C4C;*/
}
#lgame-footer {
    position: relative;
    color: white;
    width: 100%;
    min-height: fit-content;
    background-color: #00171F;
    clip-path: polygon(0 0, 20% 0, 22% 30px, 78% 30px, 80% 0, 100% 0, 100% 100%, 0 100%);
    
}
.geekmaroc-link-container{
    z-index:2;
}
.geekmaroc-link{
    color: #e7e7e7;
    font-size: 10pt;
    font-weight: 400;
}
#lgame-footer-copyright {
    background-color: red;
    color: white;
    text-transform: uppercase;
    font-size: 10px;
}
.footer-paragraph{
    color: #7E7E7E;

}

.icon-social {
    width: 18px;
    height: 18px;
}

.limiter {
    height: 60px;
    width: 100%;
}

.version-container {
    position: absolute;
    left: 10px;

}

.lgame-version {
    font-family: 'Arame Mono', 'Roboto', sans-serif;
    color: white;
    font-size: 10px;
    font-weight: 500;
}
.footer-link{
    color: #7E7E7E;
    text-decoration: none;
}
.footer-link:hover{
    color: red;
}

@media only screen and (max-width: 600px) {
    #lgame-footer {
        clip-path: polygon(0 0, 18% 0, 22% 12px, 78% 12px, 82% 0, 100% 0, 100% 100%, 0 100%);
    }

    .lgame-footer-content {
        display: flex;
        flex-direction: column;
    }

    .lgame-footer-block {
        width: 100% !important;
        margin-bottom: 1.5rem;
    }

    .lgame-footer-block div {
        width: 100% !important;
    }

    .lgame-logo {
        width: 120px !important;
    }

    .limiter {
        height: 15px;
    }
}


@media only screen and (min-width: 600px) and (max-width: 760px) {
    #lgame-footer {
        clip-path: polygon(0 0, 20% 0, 22% 15px, 78% 15px, 80% 0, 100% 0, 100% 100%, 0 100%);
    }

    .lgame-footer-content {
        display: flex;
        flex-direction: column;
    }

    .lgame-footer-block {
        width: 100% !important;
        margin-bottom: 1.5rem;
    }

    .lgame-footer-block div {
        width: 100% !important;
    }

    .lgame-logo {
        width: 160px !important;
    }

    .limiter {
        height: 15px;
    }
}
</style>
